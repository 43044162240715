@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);
@import url(https://use.typekit.net/ulk3yvk.css);
@import url(https://use.fontawesome.com/releases/v5.4.2/css/all.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-success {
  color: #28a745; }

.text-info {
  color: #17a2b8; }

.text-warning {
  color: #ffc107; }

.text-danger {
  color: #dc3545; }

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

.hide,
.sr-only {
  display: none; }

.center {
  text-align: center; }

.right {
  text-align: right; }

/* General Layout  */
html, body {
  margin: 0;
  padding: 0;
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent; }

p, li {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px; }

a {
  text-decoration: underline;
  color: #00dead;
  -webkit-font-smoothing: antialiased; }
  a:hover {
    text-decoration: none;
    color: #333;
    transition: all 0.1s ease-out; }

*:focus {
  outline: 3px solid #c1e0fe; }

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  font-size: 14px;
  font-style: italic;
  color: #555; }

h1 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

h2 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

h3 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

h4 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

h5 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

h6 {
  font-family: "proxima-nova", "Montserrat", Arial, Helvetica, sans-serif; }

.col-fifth {
  width: 20%; }

.col-fourth {
  width: 25%; }

.col-third {
  width: 33%; }

.col-half {
  width: 50%; }

.col-twothirds {
  width: 66%; }

.col-threefourths {
  width: 75%; }

.center-block {
  margin: 0 auto; }

.container {
  margin: 0 auto;
  width: 1440px; }

.intro {
  padding: 40px 0;
  text-align: center; }
  .intro h2 {
    margin: 0;
    padding: 0;
    font-size: 26px; }

.blocker {
  z-index: 3 !important; }

.modal {
  -o-box-shadow: 0 0 0 #000;
  -ms-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101; }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66);
  z-index: 100; }

.toast {
  top: 0;
  position: fixed;
  z-index: 102;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 15px; }
  .toast .close {
    right: 15px;
    position: absolute;
    color: #fff; }
  .toast .heading {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    text-align: center; }
  .toast .message {
    color: #fff;
    font-size: 16px;
    padding: 15px 0px;
    text-align: center; }

form,
.form {
  padding: 50px 0; }
  form .input, form .button,
  .form .input,
  .form .button {
    padding: 10px 0; }
  form .input .sq-input,
  .form .input .sq-input {
    box-sizing: border-box;
    border: 1px solid #efefef; }
  form .input input,
  .form .input input {
    box-sizing: border-box;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #efefef; }
  form .input select,
  .form .input select {
    box-sizing: border-box;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #efefef;
    background-color: #fff; }
  form .input select:required:invalid,
  .form .input select:required:invalid {
    color: gray; }
  form .input option[value=""][disabled],
  .form .input option[value=""][disabled] {
    display: none; }
  form .input option,
  .form .input option {
    color: black; }
  form .input .additional,
  .form .input .additional {
    padding: 10px 0; }
  form .input .error,
  .form .input .error {
    display: inline-block;
    padding: 5px 0 0 15px;
    font-size: 14px; }
  form .input .note,
  .form .input .note {
    display: inline-block;
    padding: 5px 0 0 15px;
    font-size: 14px; }
  form .input .icon-right,
  .form .input .icon-right {
    float: right;
    position: relative;
    margin-top: -26px;
    margin-right: 15px; }
    form .input .icon-right.framed,
    .form .input .icon-right.framed {
      margin-top: -29px; }
  form .input.full-size input, form .input.full-size select,
  .form .input.full-size input,
  .form .input.full-size select {
    width: 100%; }
  form .button.full-size input,
  .form .button.full-size input {
    width: 100%; }
  form .fields-side-by-side,
  .form .fields-side-by-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  form .fields-inline,
  .form .fields-inline {
    display: flex;
    flex-direction: row; }
  form .at,
  .form .at {
    padding: 10px;
    display: flex;
    align-items: center; }

.modal .form,
.modal form {
  padding: 20px 0; }

select {
  background-color: #fff; }

.radio, .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .radio a, .checkbox a {
    color: inherit;
    text-decoration: none; }
  .radio.right, .checkbox.right {
    justify-content: space-between;
    text-align: left; }
  .radio label, .checkbox label {
    width: 50%; }
  .radio input, .checkbox input {
    display: none; }
  .radio span, .checkbox span {
    font-weight: 600;
    color: #00dead;
    text-align: right;
    width: 50%; }
  .radio i.icon, .checkbox i.icon {
    cursor: pointer; }
    .radio i.icon:hover, .checkbox i.icon:hover {
      color: #00dead; }
  .radio i.checked, .checkbox i.checked {
    color: #00dead; }

.btn {
  padding: 15px 20px;
  position: relative;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  text-align: center;
  justify-content: center;
  border: 0; }
  .btn span {
    position: relative;
    z-index: 2; }
  .btn:hover {
    color: #fff; }
  .btn:hover::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); }

.btn-small {
  padding: 10px 15px; }

button.btn,
input[type=submit].btn,
input[type=button].btn {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer; }

.btn-inline {
  display: inline-flex; }

.btn-primary {
  background-color: #00dead; }

.btn-inactive {
  background-color: #ccc !important; }

.btn-group {
  display: flex;
  flex-direction: row; }

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .tabs a {
    display: inline-flex;
    justify-content: center;
    padding: 20px 40px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: center;
    border-bottom: 3px solid #00dead; }
    .tabs a:hover {
      background-color: #00dead;
      text-decoration: none;
      color: #fff; }
  .tabs a.active {
    display: block;
    background-color: #00dead;
    text-decoration: none;
    color: #fff; }

.tab-block {
  display: none; }

.tab-block.active {
  display: block; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #28a745; }

.toast-error {
  background-color: #dc3545; }

.toast-info {
  background-color: #17a2b8; }

.toast-warning {
  background-color: #ffc107; }

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
  width: 450px; }

header {
  border-bottom: 1px solid #efefef;
  padding: 0 20px;
  font-weight: 500; }
  header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    header .container .logo a {
      height: 75px;
      display: flex;
      align-items: center;
      -webkit-align-content: center;
      ss-ms-flex-line-pack: center;
      align-content: center; }
    header .container .location {
      display: flex;
      flex-direction: row;
      align-items: center;
      -webkit-align-content: center;
      ss-ms-flex-line-pack: center;
      align-content: center;
      position: relative; }
      header .container .location span {
        margin: 0 15px 0 0;
        font-size: 18px; }
      header .container .location .current-location-btn {
        position: relative;
        width: 354px;
        height: 55px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #00dead;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        cursor: pointer; }
        header .container .location .current-location-btn .current {
          width: 47%;
          height: 55px;
          min-width: 205px;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          padding: 0 15px;
          font-size: 18px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        header .container .location .current-location-btn .button {
          height: 55px;
          min-width: auto;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          padding: 0 20px;
          font-weight: 600;
          text-decoration: underline;
          border-left: 1px solid rgba(255, 255, 255, 0.5);
          justify-content: center; }
        header .container .location .current-location-btn:hover::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.2); }
    header .container .menu {
      display: flex; }
      header .container .menu a {
        height: 55px;
        display: inline-flex;
        align-items: center;
        padding: 0 15px;
        font-size: 18px;
        text-decoration: none;
        color: #333; }
        header .container .menu a i {
          margin: 0 10px 0 0;
          font-size: 24px;
          color: #aaa; }
        header .container .menu a img {
          margin: 0 10px 0 0; }
        header .container .menu a .items {
          display: inline-block;
          background-color: #00dead;
          font-weight: 600;
          color: #fff;
          margin: 0 0 0 10px;
          padding: 4px 6px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px; }
        header .container .menu a:hover {
          background-color: #00dead;
          color: #fff; }
          header .container .menu a:hover i {
            color: #fff; }
          header .container .menu a:hover .items {
            padding: 4px 6px;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px; }

.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #00dead;
  position: relative;
  min-height: 362px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .hero.small {
    min-height: 151px; }
  .hero:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3); }
  .hero h1 {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    font-size: 48px;
    font-weight: 400;
    line-height: 1em;
    color: #fff;
    text-align: center; }

footer {
  border-top: 1px solid #efefef;
  padding: 40px 20px; }
  footer .container .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 40px 0; }
    footer .container .top .branding .logo {
      display: block;
      margin: 0 0 20px 0; }
    footer .container .top .branding .website {
      display: inline-block;
      font-size: 18px;
      text-decoration: underline;
      color: #333; }
      footer .container .top .branding .website:hover {
        color: #00dead; }
    footer .container .top .branding .social {
      margin: 20px 0 0 0; }
      footer .container .top .branding .social a {
        display: inline-block;
        margin: 0 10px 0 0;
        font-size: 24px;
        font-weight: 500;
        text-decoration: none;
        color: #333; }
        footer .container .top .branding .social a:hover {
          color: #00dead; }
    footer .container .top .location h4,
    footer .container .top .app-promo h4 {
      margin: 0;
      padding: 0;
      margin: 0 0 20px 0;
      font-size: 18px;
      font-weight: 300; }
      footer .container .top .location h4 a,
      footer .container .top .app-promo h4 a {
        display: inline-block;
        margin: 0 0 0 10px;
        font-size: 18px;
        font-weight: 500; }
    footer .container .top .location .location-details {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-weight: 500; }
      footer .container .top .location .location-details div {
        margin: 0 40px 0 0; }
    footer .container .top .app-promo h4 span {
      display: inline-block;
      padding: 5px 10px;
      background-color: #00dead;
      font-weight: 300;
      color: #fff; }
    footer .container .top .app-promo .app-store-links a {
      display: inline-block; }
    footer .container .top .app-promo .app-store-links .apple {
      width: 162px;
      height: 58px;
      background-image: url(/static/media/app-store-logos.6af9a3de.png);
      background-repeat: no-repeat;
      background-position: 0 0; }
    footer .container .top .app-promo .app-store-links .android {
      width: 171px;
      height: 58px;
      background-image: url(/static/media/app-store-logos.6af9a3de.png);
      background-repeat: no-repeat;
      background-position: -162px 0; }
  footer .container .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0 0 0; }
    footer .container .bottom .legal a {
      display: inline-block;
      margin: 0 15px 0 0;
      font-size: 14px;
      font-weight: 500;
      color: #333; }
      footer .container .bottom .legal a:hover {
        text-decoration: none; }
    footer .container .bottom .poppinpay {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #aaa; }
      footer .container .bottom .poppinpay span {
        display: inline-flex;
        margin: 0 10px 0 0; }
      footer .container .bottom .poppinpay a {
        display: inline-flex; }

.about-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 40px 0; }

.category-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 0; }
  .category-list .category-spa h2.header {
    text-align: center;
    font-size: 32px;
    margin: 10px 0; }
  .category-list .category-spa h3.sub-header {
    text-align: center;
    font-size: 16px;
    margin: 0; }
  .category-list .category-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 31%;
    -ms-flexbox: 0 0 31%;
    flex: 0 0 31%;
    margin: 20px 15px;
    background-color: #fff;
    border: solid 1px #ccc; }
    .category-list .category-card .image {
      display: flex;
      height: 200px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .category-list .category-card .image:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25); }
    .category-list .category-card h2 {
      margin: 0;
      padding: 0; }
      .category-list .category-card h2 a {
        display: flex;
        padding: 20px;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none;
        color: #333; }
    .category-list .category-card:hover {
      transition: all 0.5s ease-out;
      box-shadow: 0 0 10px #aaa; }

.select-category {
  background-color: #fff;
  border-bottom: solid 1px #ccc; }
  .select-category .categories-menu {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .select-category .categories-menu button {
      background-color: transparent;
      color: #000;
      border: 0;
      cursor: pointer; }
    .select-category .categories-menu .slick-list {
      width: 60%; }
      @media screen and (max-width: 1440px) {
        .select-category .categories-menu .slick-list {
          width: 100%; } }
      .select-category .categories-menu .slick-list .active-category a {
        background-color: #00dead;
        color: #fff; }
      .select-category .categories-menu .slick-list a {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        text-align: center;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        position: relative; }
        .select-category .categories-menu .slick-list a span {
          display: block;
          position: relative;
          z-index: 1;
          white-space: nowrap;
          overflow: hidden; }
        .select-category .categories-menu .slick-list a:hover::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.25); }

.sub-category-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0; }
  .sub-category-list .item-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 31%;
    -ms-flexbox: 0 0 31%;
    flex: 0 0 31%;
    margin: 20px 15px;
    background-color: #fff;
    border: solid 1px #ccc; }
    .sub-category-list .item-card .image {
      display: flex;
      height: 185px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .sub-category-list .item-card .image .favorite {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #fff; }
      .sub-category-list .item-card .image:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25); }
    .sub-category-list .item-card .description {
      height: 229px;
      min-height: 229px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .sub-category-list .item-card .description .header {
        margin: 20px;
        position: relative;
        max-height: 171px;
        overflow: hidden; }
        .sub-category-list .item-card .description .header h2 {
          margin: 0;
          padding: 0;
          padding: 0 15px 0 0;
          font-size: 24px;
          font-weight: bold;
          text-decoration: none;
          color: #333; }
        .sub-category-list .item-card .description .header p {
          font-weight: 500;
          color: #555; }
      .sub-category-list .item-card .description .footer {
        display: flex;
        flex-direction: row; }
        .sub-category-list .item-card .description .footer .price,
        .sub-category-list .item-card .description .footer .btn {
          height: 70px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          -webkit-box: 0;
          -moz-box: 0;
          -webkit-flex: 0 0 50%;
          -ms-flexbox: 0 0 50%;
          flex: 0 0 50%; }
        .sub-category-list .item-card .description .footer .price {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          background-color: #f9f9f9; }
          .sub-category-list .item-card .description .footer .price span {
            display: inline-block;
            margin: 0 10px 0 0;
            font-weight: 300;
            text-decoration: line-through;
            color: #aaa; }
        .sub-category-list .item-card .description .footer .btn {
          padding: 0; }

.product-details-sidebar-modal {
  display: none;
  width: 550px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  overflow: auto;
  box-shadow: 0 0 100px #000;
  background-color: #fff; }
  .product-details-sidebar-modal .inner {
    padding: 40px; }
    .product-details-sidebar-modal .inner .title-and-price {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0 10px 0; }
      .product-details-sidebar-modal .inner .title-and-price h2 {
        margin: 0;
        padding: 0;
        font-size: 26px; }
      .product-details-sidebar-modal .inner .title-and-price .price {
        margin-top: 10px;
        font-size: 20px;
        color: #00dead;
        text-align: right; }
    .product-details-sidebar-modal .inner .photo {
      padding: 10px 0; }
      .product-details-sidebar-modal .inner .photo img {
        width: 100%; }
    .product-details-sidebar-modal .inner .description {
      margin: 0 0 30px 0;
      font-size: 16px;
      color: #555; }
    .product-details-sidebar-modal .inner .disclaimer {
      padding: 10px;
      background-color: #f5f5f5;
      font-size: 10px;
      color: #555; }
    .product-details-sidebar-modal .inner h3 {
      margin: 0;
      padding: 0;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: 300; }
      .product-details-sidebar-modal .inner h3 span .req {
        display: inline-flex;
        margin: 0 0 0 5px;
        color: red; }
    .product-details-sidebar-modal .inner .extras h3 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ccc; }
      .product-details-sidebar-modal .inner .extras h3 span {
        font-weight: 500; }
      .product-details-sidebar-modal .inner .extras h3 i {
        cursor: pointer; }
        .product-details-sidebar-modal .inner .extras h3 i:hover {
          color: #00dead; }
      .product-details-sidebar-modal .inner .extras h3 i.more {
        display: none; }
    .product-details-sidebar-modal .inner .extras ul {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: #f5f5f5; }
      .product-details-sidebar-modal .inner .extras ul li {
        padding: 15px 15px;
        font-size: 14px; }
        .product-details-sidebar-modal .inner .extras ul li i {
          margin-left: 5px;
          font-size: 18px; }
    .product-details-sidebar-modal .inner section {
      border-top: 1px solid #ccc; }
    .product-details-sidebar-modal .inner .notes {
      width: 100%; }
      .product-details-sidebar-modal .inner .notes h3 {
        font-weight: 500; }
      .product-details-sidebar-modal .inner .notes textarea {
        box-sizing: border-box;
        width: 100%;
        height: 100px;
        padding: 20px;
        font-size: 12px;
        background-color: white;
        border: 1px solid lightgray; }
    .product-details-sidebar-modal .inner .qty {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .product-details-sidebar-modal .inner .qty h3 {
        font-weight: 500; }
      .product-details-sidebar-modal .inner .qty .actions {
        display: flex;
        flex-direction: row; }
        .product-details-sidebar-modal .inner .qty .actions a {
          width: 40px;
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          text-decoration: none; }
          .product-details-sidebar-modal .inner .qty .actions a:hover {
            color: #fff;
            background-color: #00dead; }
        .product-details-sidebar-modal .inner .qty .actions .qty-num {
          width: 40px;
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 20px; }
    .product-details-sidebar-modal .inner .total {
      padding: 20px 0;
      font-size: 26px;
      color: #00dead;
      text-align: center;
      border-top: 1px solid #00dead; }

.home-locations .inner {
  display: flex; }
  .home-locations .inner .left {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    padding: 0 15px;
    overflow-y: auto;
    background-color: #f5f5f5;
    height: calc(100vh - 76px); }
  .home-locations .inner .right {
    display: flex;
    flex-basis: 75%; }

.large-locations-modal {
  height: 90%;
  max-width: 80%; }
  .large-locations-modal .close {
    right: 15px;
    position: absolute; }
  .large-locations-modal .wrap {
    height: 100%; }
  .large-locations-modal .inner {
    display: flex;
    height: 100%; }
    .large-locations-modal .inner .left {
      display: flex;
      flex-direction: column;
      flex-basis: 40%;
      padding: 0 15px;
      overflow-y: auto;
      margin-top: 40px;
      background-color: #f5f5f5; }
    .large-locations-modal .inner .right {
      display: flex;
      flex-basis: 75%;
      margin-top: 40px; }

.locations-modal .info-window-location {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-right: 20px;
  min-width: 200px; }
  .locations-modal .info-window-location .title {
    clear: both; }
    .locations-modal .info-window-location .title span {
      float: right;
      background-color: #00dead;
      border: 1px solid #00dead;
      color: #fff;
      border-radius: 64px;
      padding: 4px 16px;
      margin-top: -4px; }
  .locations-modal .info-window-location p {
    margin: 0.5em 0; }

.locations-modal .location {
  padding: 20px;
  margin: 0 0 20px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: 4px solid #ccc; }
  .locations-modal .location.active {
    border: 1px solid #00dead;
    border-left: 4px solid #00dead; }
    .locations-modal .location.active .title span {
      background-color: #00dead;
      border: 1px solid #00dead; }
  .locations-modal .location div {
    margin: 10px 0; }
  .locations-modal .location .phone {
    margin: 1em 0; }
  .locations-modal .location .hours {
    margin: 1em 0; }
  .locations-modal .location .title {
    clear: both;
    font-size: 18px; }
    .locations-modal .location .title span {
      float: right;
      background-color: #ccc;
      border: 1px solid #ccc;
      color: #fff;
      border-radius: 64px;
      padding: 4px 16px;
      margin-top: -4px; }

.verify-account-modal button {
  display: inline-block;
  width: 50%; }

.bag .body {
  padding: 0 0 80px 0; }

.bag .bag-full-of-stuff {
  background-color: #fff;
  border-top: 1px solid #efefef; }
  .bag .bag-full-of-stuff .row {
    border-bottom: 1px solid #efefef;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    display: flex;
    flex-direction: row;
    align-items: stretch; }
    .bag .bag-full-of-stuff .row .pic {
      align-items: center;
      width: 200px; }
      .bag .bag-full-of-stuff .row .pic img {
        display: block;
        width: 100%; }
    .bag .bag-full-of-stuff .row .title-info {
      padding: 0 40px;
      display: inline-flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center; }
      .bag .bag-full-of-stuff .row .title-info h3 {
        margin: 0;
        padding: 0;
        margin: 0 0 5px 0;
        display: flex;
        align-items: center; }
        .bag .bag-full-of-stuff .row .title-info h3 a {
          display: inline-block;
          margin-right: 5px; }
        .bag .bag-full-of-stuff .row .title-info h3 .favorite i:hover {
          color: #00dead; }
      .bag .bag-full-of-stuff .row .title-info .description {
        margin: 0 0 5px 0; }
      .bag .bag-full-of-stuff .row .title-info .notes {
        margin: 0 0 5px 0;
        font-weight: 300;
        font-style: italic;
        color: #aaa; }
    .bag .bag-full-of-stuff .row .qty {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 0 20px; }
      .bag .bag-full-of-stuff .row .qty a {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #efefef;
        text-decoration: none; }
        .bag .bag-full-of-stuff .row .qty a:hover {
          color: #fff;
          background-color: #00dead; }
      .bag .bag-full-of-stuff .row .qty .qty-num {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        background-color: #f5f5f5; }
    .bag .bag-full-of-stuff .row .price {
      width: 130px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 400;
      background-color: #efefef; }
    .bag .bag-full-of-stuff .row .edit {
      width: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5; }
    .bag .bag-full-of-stuff .row .remove {
      width: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
  .bag .bag-full-of-stuff .coupon .pic {
    min-width: 200px;
    display: flex;
    justify-content: center; }
    .bag .bag-full-of-stuff .coupon .pic i {
      color: #aaa; }
  .bag .bag-full-of-stuff .coupon .title-info {
    margin: 20px 0px; }
  .bag .bag-full-of-stuff .coupon .remove {
    min-width: 100px; }
  .bag .bag-full-of-stuff .subtotal,
  .bag .bag-full-of-stuff .discount,
  .bag .bag-full-of-stuff .tax,
  .bag .bag-full-of-stuff .total {
    display: flex;
    flex-direction: row; }
    .bag .bag-full-of-stuff .subtotal .txt,
    .bag .bag-full-of-stuff .discount .txt,
    .bag .bag-full-of-stuff .tax .txt,
    .bag .bag-full-of-stuff .total .txt {
      padding: 0px 40px;
      display: inline-flex;
      flex-grow: 1;
      justify-content: flex-end; }
    .bag .bag-full-of-stuff .subtotal .price,
    .bag .bag-full-of-stuff .discount .price,
    .bag .bag-full-of-stuff .tax .price,
    .bag .bag-full-of-stuff .total .price {
      padding: 5px 40px 5px 0;
      width: 130px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      color: #00dead; }
    .bag .bag-full-of-stuff .subtotal .empty,
    .bag .bag-full-of-stuff .discount .empty,
    .bag .bag-full-of-stuff .tax .empty,
    .bag .bag-full-of-stuff .total .empty {
      width: 200px; }
  .bag .bag-full-of-stuff .subtotal {
    padding: 20px 0 0 0; }
  .bag .bag-full-of-stuff .total {
    margin: 20px 0 0 0;
    padding: 20px 0;
    border-top: 1px solid #efefef; }
    .bag .bag-full-of-stuff .total .txt {
      font-size: 20px; }
    .bag .bag-full-of-stuff .total .price {
      font-size: 20px;
      color: #00dead; }

.bag .checkout-ctas {
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .bag .checkout-ctas a, .bag .checkout-ctas span {
    padding: 10px 20px;
    font-size: 20px; }

.account .account-status-buttons {
  padding: 60px 0 0 0; }
  .account .account-status-buttons a {
    width: 50%; }

.my-account-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #00dead; }
  .my-account-menu div {
    display: inline-flex; }
    .my-account-menu div a {
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      text-align: center; }
      .my-account-menu div a span {
        display: block;
        position: relative;
        z-index: 1; }
      .my-account-menu div a:hover::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25); }
    .my-account-menu div a.active::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.25); }

.my-account-sub-menu {
  display: flex;
  flex-direction: row;
  justify-content: left; }
  .my-account-sub-menu div {
    display: inline-flex; }
    .my-account-sub-menu div a {
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      position: relative;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      text-align: center; }
      .my-account-sub-menu div a span {
        display: block;
        position: relative;
        z-index: 1; }
      .my-account-sub-menu div a:hover::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.25); }
    .my-account-sub-menu div a.active {
      font-size: 20px;
      font-weight: 1000;
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      text-align: center; }
    .my-account-sub-menu div a.active::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom: 5px solid #00dead; }

.my-acct-saved-cards .body .container {
  padding: 50px 0; }
  .my-acct-saved-cards .body .container .add-new-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .my-acct-saved-cards .body .container .card-grid {
    margin: 20px 0; }
    .my-acct-saved-cards .body .container .card-grid .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .my-acct-saved-cards .body .container .card-grid .row div {
        height: 60px;
        padding: 0 15px;
        border-bottom: 1px solid #aaa;
        display: inline-flex;
        align-items: center; }
      .my-acct-saved-cards .body .container .card-grid .row small {
        margin-left: 4px; }
      .my-acct-saved-cards .body .container .card-grid .row .card-type i {
        font-size: 32px;
        color: #00dead; }
      .my-acct-saved-cards .body .container .card-grid .row .name {
        flex-grow: 1; }

.my-acct-my-favorites .body .container {
  padding: 50px 0; }
  .my-acct-my-favorites .body .container .favorites-list .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #aaa;
    align-items: center;
    padding: 20px 0; }
    .my-acct-my-favorites .body .container .favorites-list .row .fav {
      padding: 0 20px 0 0; }
      .my-acct-my-favorites .body .container .favorites-list .row .fav i {
        font-size: 36px; }
    .my-acct-my-favorites .body .container .favorites-list .row .pic {
      align-items: center;
      width: 200px; }
      .my-acct-my-favorites .body .container .favorites-list .row .pic img {
        display: block;
        width: 100%; }
    .my-acct-my-favorites .body .container .favorites-list .row .title-info {
      padding: 0 40px;
      display: inline-flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center; }
      .my-acct-my-favorites .body .container .favorites-list .row .title-info h3 {
        margin: 0;
        padding: 0;
        margin: 0 0 5px 0;
        font-size: 20px; }
      .my-acct-my-favorites .body .container .favorites-list .row .title-info .description {
        margin: 0 0 5px 0; }

.my-acct-order-history .body .container {
  padding: 50px 0; }
  .my-acct-order-history .body .container .filter {
    display: flex;
    flex-direction: row; }
    .my-acct-order-history .body .container .filter div {
      padding: 20px 20px 20px 0;
      display: inline-flex;
      align-items: center; }
      .my-acct-order-history .body .container .filter div strong {
        font-size: 20px; }
      .my-acct-order-history .body .container .filter div select {
        font-size: 20px; }
  .my-acct-order-history .body .container .order-history-list .row {
    padding: 20px;
    margin: 0 0 40px 0;
    background-color: #fff;
    border: 1px solid #00dead;
    border-left: 4px solid #00dead; }
    .my-acct-order-history .body .container .order-history-list .row h2 {
      padding: 0 0 0 0;
      margin: 0 0 40px 0;
      font-size: 26px;
      font-weight: 400;
      color: #00dead; }
    .my-acct-order-history .body .container .order-history-list .row .title {
      display: flex;
      flex-direction: row; }
      .my-acct-order-history .body .container .order-history-list .row .title .detail {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-self: "flex-start"; }
      .my-acct-order-history .body .container .order-history-list .row .title .reorder {
        align-self: "flex-end"; }
    .my-acct-order-history .body .container .order-history-list .row .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      border-bottom: 1px solid #efefef; }
      .my-acct-order-history .body .container .order-history-list .row .item .pic {
        align-items: center;
        width: 200px; }
        .my-acct-order-history .body .container .order-history-list .row .item .pic img {
          display: block;
          width: 100%; }
      .my-acct-order-history .body .container .order-history-list .row .item .details {
        padding: 0 40px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: 70%; }
        .my-acct-order-history .body .container .order-history-list .row .item .details h3 {
          margin: 0;
          padding: 0;
          margin: 0 0 5px 0; }
          .my-acct-order-history .body .container .order-history-list .row .item .details h3 .favorite i:hover {
            color: #00dead; }
        .my-acct-order-history .body .container .order-history-list .row .item .details .description, .my-acct-order-history .body .container .order-history-list .row .item .details .qty {
          margin: 0 0 5px 0; }
        .my-acct-order-history .body .container .order-history-list .row .item .details .btn {
          margin: 0 0 5px 0; }
        .my-acct-order-history .body .container .order-history-list .row .item .details .notes {
          margin: 0 0 5px 0;
          font-weight: 300;
          font-style: italic;
          color: #aaa; }
        .my-acct-order-history .body .container .order-history-list .row .item .details .reorder-note {
          margin: 0 0 5px 0;
          font-weight: 300;
          font-style: italic; }
      .my-acct-order-history .body .container .order-history-list .row .item .price {
        width: 25%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        color: #00dead;
        text-align: right; }
  .my-acct-order-history .body .container .order-history-list .price-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 20px 0; }
    .my-acct-order-history .body .container .order-history-list .price-summary .txt {
      width: 70%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      text-align: right; }
    .my-acct-order-history .body .container .order-history-list .price-summary .totals {
      width: 25%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      color: #00dead;
      text-align: right; }

.my-acct-my-rewards .body .container {
  padding: 50px 0; }
  .my-acct-my-rewards .body .container .sub-heading {
    text-align: center; }
  .my-acct-my-rewards .body .container .rewards-amount {
    background-color: #00dead; }
    .my-acct-my-rewards .body .container .rewards-amount h2 {
      margin: 0;
      padding: 0;
      padding: 40px 0;
      font-size: 36px;
      color: #fff;
      text-align: center; }
  .my-acct-my-rewards .body .container p, .my-acct-my-rewards .body .container span {
    font-size: 16px; }
  .my-acct-my-rewards .body .container .rewards-list .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #aaa;
    align-items: center;
    padding: 20px 0; }
    .my-acct-my-rewards .body .container .rewards-list .row a {
      white-space: nowrap; }
    .my-acct-my-rewards .body .container .rewards-list .row .pic {
      align-items: center;
      padding: 0 0 0 20px; }
      .my-acct-my-rewards .body .container .rewards-list .row .pic i {
        display: block;
        color: #aaa; }
    .my-acct-my-rewards .body .container .rewards-list .row .title-info {
      padding: 0 40px;
      display: inline-flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center; }
      .my-acct-my-rewards .body .container .rewards-list .row .title-info h3 {
        margin: 0;
        padding: 0;
        margin: 0 0 5px 0;
        font-size: 20px; }
      .my-acct-my-rewards .body .container .rewards-list .row .title-info .description {
        margin: 0 0 5px 0; }
      .my-acct-my-rewards .body .container .rewards-list .row .title-info .notes {
        margin: 0 0 5px 0;
        font-weight: 300;
        font-style: italic;
        color: #aaa; }

.checkout .body .container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.checkout .information section {
  padding: 20px 40px;
  margin: 0 0 20px 0;
  border-left: 2px solid #00dead; }
  .checkout .information section h3 {
    margin: 0;
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 26px;
    font-weight: 400; }
  .checkout .information section .btn {
    padding: 10px 15px; }
  .checkout .information section .loyalty-box {
    display: none; }
  .checkout .information section .current-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .checkout .information section .current-card div {
      display: inline-flex;
      align-items: center; }
      .checkout .information section .current-card div small {
        margin-left: 4px; }
      .checkout .information section .current-card div i {
        margin: 0 10px 0 0;
        color: #00dead; }
  .checkout .information section .guest-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .checkout .information section .guest-profile div {
      display: inline-flex;
      align-items: center;
      align-self: start; }
      .checkout .information section .guest-profile div.detail {
        display: block; }
  .checkout .information section .card-list .card {
    border-bottom: solid 1px #aaa;
    padding: 8px 0; }
  .checkout .information section .fields-side-by-side .btn {
    margin: 8px 4px; }
  .checkout .information section .rewards-total {
    padding: 10px 0;
    font-size: 26px;
    font-weight: 600;
    color: #00dead;
    text-align: center;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef; }
  .checkout .information section .reward {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .checkout .information section .reward div {
      display: inline-flex;
      padding: 5px 10px; }
    .checkout .information section .reward .title {
      flex-grow: 1;
      font-size: 18px;
      align-items: center; }
      .checkout .information section .reward .title input {
        margin: 0 10px 0 0; }
    .checkout .information section .reward .amount {
      text-align: right;
      justify-content: flex-end; }

.checkout .cart-summary {
  margin: 0 0 20px 0; }
  .checkout .cart-summary .inner {
    padding: 40px;
    background-color: #fff; }
    .checkout .cart-summary .inner h2 {
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      font-size: 26px;
      font-weight: 400;
      border-bottom: 1px solid #efefef; }
    .checkout .cart-summary .inner .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      border-bottom: 1px solid #efefef; }
      .checkout .cart-summary .inner .item .details {
        width: 70%; }
        .checkout .cart-summary .inner .item .details h3 {
          margin: 0;
          padding: 0;
          margin: 0 0 5px 0; }
          .checkout .cart-summary .inner .item .details h3 .favorite i:hover {
            color: #00dead; }
        .checkout .cart-summary .inner .item .details .description, .checkout .cart-summary .inner .item .details .qty {
          margin: 0 0 5px 0; }
        .checkout .cart-summary .inner .item .details .notes {
          margin: 0 0 5px 0;
          font-weight: 300;
          font-style: italic;
          color: #aaa; }
      .checkout .cart-summary .inner .item .price {
        width: 25%;
        display: inline-flex;
        justify-content: flex-end;
        font-size: 16px;
        color: #00dead;
        text-align: right; }
    .checkout .cart-summary .inner .checkout-message {
      border-top: 1px solid #efefef;
      padding: 10px 0 10px 0;
      margin: 0 0 10px 0; }
  .checkout .cart-summary .price-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 20px 0; }
    .checkout .cart-summary .price-summary .txt {
      width: 70%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      text-align: right; }
    .checkout .cart-summary .price-summary .totals {
      width: 25%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      color: #00dead;
      text-align: right; }

.checkout .curbside-form .checkbox label {
  font-size: 16px;
  line-height: 1.4em;
  text-align: left; }

.checkout .curbside-form .fields-side-by-side .btn {
  margin: 8px 4px; }

.checkout .curbside-form textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 12px;
  background-color: white;
  border: 1px solid lightgray; }

.confirmation .panels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 100px 0; }
  .confirmation .panels .col-third {
    background-color: #fff;
    border: 1px solid #efefef; }
    .confirmation .panels .col-third .title {
      padding: 30px;
      background-color: #efefef; }
      .confirmation .panels .col-third .title h2 {
        margin: 0;
        padding: 0; }
      .confirmation .panels .col-third .title p {
        margin: 0; }
    .confirmation .panels .col-third .inner {
      padding: 30px; }
  .confirmation .panels .what .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #efefef; }
    .confirmation .panels .what .item h3 {
      margin: 0;
      padding: 0;
      margin: 0 0 5px 0; }
      .confirmation .panels .what .item h3 .favorite i:hover {
        color: #00dead; }
    .confirmation .panels .what .item .description, .confirmation .panels .what .item .qty {
      margin: 0 0 5px 0; }
    .confirmation .panels .what .item .notes {
      margin: 0 0 5px 0;
      font-weight: 300;
      font-style: italic;
      color: #aaa; }
  .confirmation .panels .what .price-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 20px 0; }
    .confirmation .panels .what .price-summary .txt {
      width: 70%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      text-align: right; }
    .confirmation .panels .what .price-summary .totals {
      width: 25%;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 1.4em;
      color: #00dead;
      text-align: right; }

@media screen and (max-width: 1440px) {
  .body {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .container {
    width: 100%; }
  .large-locations-modal .inner .left .location .title span {
    padding: 2px 8px;
    margin-top: 0px;
    font-size: 14px; } }

@media screen and (max-width: 1410px) {
  .category-list .category-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 30%;
    -ms-flexbox: 0 0 30%;
    flex: 0 0 30%; }
  .sub-category-list .item-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 30%;
    -ms-flexbox: 0 0 30%;
    flex: 0 0 30%; } }

@media screen and (max-width: 1024px) {
  header .container {
    flex-wrap: wrap; }
    header .container .logo a {
      margin: 10px auto 0 auto;
      width: auto;
      height: 50px; }
      header .container .logo a img {
        width: auto;
        height: 50px; }
    header .container .location,
    header .container .menu {
      height: 70px;
      align-items: center; }
  footer .container .top {
    flex-wrap: wrap; }
    footer .container .top .branding {
      padding: 0 0 30px 0;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      text-align: center; }
      footer .container .top .branding .logo {
        margin: 0 auto 20px auto; }
  .large-locations-modal .inner .left {
    flex-basis: 33%; }
  .large-locations-modal .inner .right {
    flex-basis: 66%; } }

@media screen and (max-width: 999px) {
  .category-list .category-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 45%;
    -ms-flexbox: 0 0 45%;
    flex: 0 0 45%; }
  .sub-category-list .item-card {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 45%;
    -ms-flexbox: 0 0 45%;
    flex: 0 0 45%; } }

@media screen and (max-width: 980px) {
  .col-half {
    width: 100%; }
  .col-third {
    width: 100%; }
  header .container {
    flex-wrap: wrap;
    flex-direction: column; }
    header .container .logo a {
      margin-bottom: 10px; }
      header .container .logo a img {
        width: auto;
        height: 50px; }
    header .container .location {
      width: 80%;
      justify-content: center; }
      header .container .location span {
        margin: 0 0 10px 0;
        width: 230px; }
      header .container .location .current-location-btn {
        width: 350px;
        height: auto;
        display: flex;
        justify-content: space-between; }
        header .container .location .current-location-btn .current {
          width: auto;
          height: auto;
          min-width: 50%;
          padding: 10px 20px;
          text-align: center; }
        header .container .location .current-location-btn .button {
          min-width: auto; }
    header .container .menu {
      width: 80%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
  .product-details-sidebar-modal {
    width: 70%; }
  .bag .bag-full-of-stuff {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    justify-content: space-between; }
    .bag .bag-full-of-stuff .row {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 48%;
      -ms-flexbox: 0 0 48%;
      flex: 0 0 48%;
      flex-direction: column;
      align-items: initial;
      background-color: #fff;
      margin: 15px 0; }
      .bag .bag-full-of-stuff .row .pic {
        width: 100%; }
      .bag .bag-full-of-stuff .row .title-info {
        padding: 20px 40px;
        justify-content: flex-start;
        text-align: center; }
      .bag .bag-full-of-stuff .row .qty {
        padding: 0 40px 20px 40px;
        justify-content: center; }
      .bag .bag-full-of-stuff .row .price {
        width: 100%;
        padding: 20px 0; }
      .bag .bag-full-of-stuff .row .remove {
        width: 100%;
        padding: 20px 0; }
        .bag .bag-full-of-stuff .row .remove a i {
          display: none; }
        .bag .bag-full-of-stuff .row .remove a:before {
          content: 'REMOVE';
          display: block;
          font-size: 20px;
          font-weight: 600; }
    .bag .bag-full-of-stuff .subtotal {
      margin: 20px 0 0 0; }
    .bag .bag-full-of-stuff .subtotal,
    .bag .bag-full-of-stuff .tax,
    .bag .bag-full-of-stuff .total {
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%; }
      .bag .bag-full-of-stuff .subtotal .txt,
      .bag .bag-full-of-stuff .subtotal .price,
      .bag .bag-full-of-stuff .tax .txt,
      .bag .bag-full-of-stuff .tax .price,
      .bag .bag-full-of-stuff .total .txt,
      .bag .bag-full-of-stuff .total .price {
        flex-grow: 0;
        width: 50%;
        padding: 5px 20px; }
      .bag .bag-full-of-stuff .subtotal .txt,
      .bag .bag-full-of-stuff .tax .txt,
      .bag .bag-full-of-stuff .total .txt {
        justify-content: flex-end; }
      .bag .bag-full-of-stuff .subtotal .price,
      .bag .bag-full-of-stuff .tax .price,
      .bag .bag-full-of-stuff .total .price {
        justify-content: flex-start; }
      .bag .bag-full-of-stuff .subtotal .empty,
      .bag .bag-full-of-stuff .tax .empty,
      .bag .bag-full-of-stuff .total .empty {
        display: none; }
  .checkout .body .container {
    flex-wrap: wrap;
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 100%;
    -ms-flexbox: 0 0 100%;
    flex: 0 0 100%; }
  .confirmation .panels {
    flex-wrap: wrap;
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 100%;
    -ms-flexbox: 0 0 100%;
    flex: 0 0 100%; }
  .my-account-menu div a {
    padding: 0 10px;
    font-size: 16px; }
  footer .container .top {
    flex-wrap: wrap; }
    footer .container .top .branding,
    footer .container .top .location,
    footer .container .top .app-promo {
      padding: 0 0 30px 0;
      margin: 0 auto;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      text-align: center; }
    footer .container .top .location {
      padding: 30px 0;
      margin: 0 auto 30px auto;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef; }
      footer .container .top .location .location-details .address,
      footer .container .top .location .location-details .hours,
      footer .container .top .location .location-details .phone {
        margin: 0; }
  .locations-modal .wrap .inner {
    flex-direction: column;
    position: relative; }
    .locations-modal .wrap .inner .left {
      flex-basis: 100%;
      width: 100%;
      height: 50%;
      display: block;
      position: absolute;
      bottom: 0;
      margin-top: 0 !important;
      padding: 0; }
      .locations-modal .wrap .inner .left .form {
        padding: 72px 20px 20px 20px; }
      .locations-modal .wrap .inner .left .location {
        margin: 0 20px 20px 20px !important; }
    .locations-modal .wrap .inner .right {
      flex-basis: 100%;
      width: 100%;
      height: 50%;
      display: block;
      position: absolute;
      top: 0; }
  .home-locations .wrap .inner {
    position: inherit; }
  .large-locations-modal {
    height: 100%;
    width: 100%;
    max-width: 100%; } }

@media screen and (max-width: 768px) {
  html, body {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .modal select {
    width: 100%; }
  header .container .location span {
    width: 230px;
    margin: 0 0 10px 0; }
  header .container .location .current-location-btn {
    width: 350px;
    height: auto;
    display: flex;
    justify-content: space-between; }
    header .container .location .current-location-btn .current {
      height: auto;
      padding: 10px 20px;
      text-align: center; }
  header .container .menu {
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .category-list .category-card {
    margin: 0 0 20px 0;
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 100%;
    -ms-flexbox: 0 0 100%;
    flex: 0 0 100%; }
  .sub-category-list .item-card {
    margin: 0 0 20px 0;
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 100%;
    -ms-flexbox: 0 0 100%;
    flex: 0 0 100%; }
  .bag .bag-full-of-stuff .row {
    -webkit-box: 0;
    -moz-box: 0;
    -webkit-flex: 0 0 100%;
    -ms-flexbox: 0 0 100%;
    flex: 0 0 100%; }
    .bag .bag-full-of-stuff .row .title-info h3 {
      justify-content: center; }
    .bag .bag-full-of-stuff .row .edit {
      width: 100%;
      padding: 10px 0;
      background-color: white;
      border-bottom: 1px solid lightgray; }
  .product-details-sidebar-modal {
    width: auto; }
  .product-details-sidebar-modal .inner {
    padding: 40px 40px 150px 40px; }
  .product-details-sidebar-modal .floating-button {
    width: 100%;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #ccc; }
  .product-details-sidebar-modal .floating-button button {
    width: 90%;
    margin: 0 auto; }
  .my-account-menu {
    flex-wrap: wrap; }
    .my-account-menu div {
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 33%;
      -ms-flexbox: 0 0 33%;
      flex: 0 0 33%; }
      .my-account-menu div a {
        width: 100%;
        justify-content: center; }
  footer .container .top .location-details {
    display: flex;
    flex-direction: column !important;
    justify-content: initial; }
    footer .container .top .location-details .address,
    footer .container .top .location-details .hours,
    footer .container .top .location-details .phone {
      padding: 0 0 10px 0;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%; }
  footer .container .bottom {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    footer .container .bottom .legal,
    footer .container .bottom .poppinpay {
      padding: 20px 0;
      text-align: center;
      justify-content: center; }
  .my-acct-saved-cards .body .container .card-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .my-acct-saved-cards .body .container .card-grid .row {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      flex-direction: column;
      background-color: #fff;
      margin: 0 0 10px 0;
      padding: 10px 0 20px 0;
      border: 1px solid #efefef; }
      .my-acct-saved-cards .body .container .card-grid .row div {
        border: 0; }
  .my-acct-my-favorites .body .container .favorites-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .my-acct-my-favorites .body .container .favorites-list .row {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      flex-direction: column;
      background-color: #fff;
      margin: 0 0 10px 0;
      padding: 10px 0 20px 0;
      border: 1px solid #efefef; }
      .my-acct-my-favorites .body .container .favorites-list .row div {
        border: 0; }
      .my-acct-my-favorites .body .container .favorites-list .row .fav {
        padding: 0 0 10px 0; }
      .my-acct-my-favorites .body .container .favorites-list .row .pic {
        width: 100%; }
      .my-acct-my-favorites .body .container .favorites-list .row .title-info {
        padding: 20px 0;
        text-align: center; }
  .my-acct-order-history .body .container .order-history-list .row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .my-acct-order-history .body .container .order-history-list .row h2 {
      margin: 0 0 10px 0; }
    .my-acct-order-history .body .container .order-history-list .row .title {
      flex-direction: column; }
      .my-acct-order-history .body .container .order-history-list .row .title .reorder {
        align-self: center;
        margin: 0 0 10px 0; }
    .my-acct-order-history .body .container .order-history-list .row .item {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      flex-direction: column;
      background-color: #fff;
      margin: 0 0 15px 0;
      padding: 0;
      border: 1px solid #efefef; }
      .my-acct-order-history .body .container .order-history-list .row .item div {
        border: 0; }
      .my-acct-order-history .body .container .order-history-list .row .item .pic {
        width: 100%; }
      .my-acct-order-history .body .container .order-history-list .row .item .details {
        width: 100%;
        padding: 20px 0 10px 0;
        text-align: center; }
      .my-acct-order-history .body .container .order-history-list .row .item .price {
        width: 100%;
        padding: 10px 0 20px 0;
        font-size: 22px;
        justify-content: center; }
  .my-acct-my-rewards .body .container .rewards-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .my-acct-my-rewards .body .container .rewards-list .row {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100%;
      -ms-flexbox: 0 0 100%;
      flex: 0 0 100%;
      flex-direction: column;
      background-color: #fff;
      margin: 0 0 10px 0;
      padding: 10px 0 20px 0;
      border: 1px solid #efefef; }
      .my-acct-my-rewards .body .container .rewards-list .row div {
        border: 0; }
      .my-acct-my-rewards .body .container .rewards-list .row .pic {
        width: 100%; }
      .my-acct-my-rewards .body .container .rewards-list .row .title-info {
        padding: 20px 0;
        text-align: center; } }

@media screen and (max-width: 425px) {
  header .container .location {
    height: auto;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    flex-direction: column; }
    header .container .location span {
      width: 230px;
      margin: 0 0 10px 0; }
    header .container .location .current-location-btn {
      width: 350px;
      height: auto;
      display: flex;
      justify-content: space-between; }
      header .container .location .current-location-btn .current {
        height: auto;
        padding: 10px 20px;
        text-align: center; }
  header .container .menu {
    width: 80%;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    header .container .menu a {
      height: 45px; }
  .hero {
    min-height: 170px; }
    .hero h1 {
      padding: 0 20px;
      font-size: 36px; }
  .current-card {
    flex-wrap: wrap;
    padding-bottom: 10px; }
    .current-card div:first-child {
      display: flex;
      -webkit-box: 0;
      -moz-box: 0;
      -webkit-flex: 0 0 100% 0 auto;
      -ms-flexbox: 0 0 100% 0 auto;
      flex: 0 0 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 0 5px 0; }
      .current-card div:first-child i {
        -webkit-box: 0;
        -moz-box: 0;
        -webkit-flex: 0 0 100% 0 auto;
        -ms-flexbox: 0 0 100% 0 auto;
        flex: 0 0 100%;
        margin: 0 0 10px 0 !important;
        text-align: left; }
      .current-card div:first-child span {
        display: block;
        font-size: 20px;
        padding: 10px 10px 10px 0;
        border-right: 2px solid #ddd; }
      .current-card div:first-child small {
        display: block;
        font-size: 14px;
        padding: 10px 0 10px 10px; }
  .pickup-date-and-time .fields-inline {
    flex-wrap: wrap; }
    .pickup-date-and-time .fields-inline .full-size {
      -webkit-box: 1;
      -moz-box: 1;
      -webkit-flex: 1 0 200px 0 auto;
      -ms-flexbox: 1 0 200px 0 auto;
      flex: 1 0 200px; }
    .pickup-date-and-time .fields-inline .full-size:first-child {
      -webkit-box: 1;
      -moz-box: 1;
      -webkit-flex: 1 0 100% 0 auto;
      -ms-flexbox: 1 0 100% 0 auto;
      flex: 1 0 100%; } }

@media screen and (max-width: 375px) {
  header .container .location .current-location-btn {
    width: 300px;
    height: auto; }
    header .container .location .current-location-btn .current {
      min-width: 46%; }
  header .container .menu {
    width: 100%; }
  .modify-tip-modal .btn-group .btn {
    padding: 15px 0;
    font-size: 14px; } }

@media screen and (max-width: 320px) {
  .current-card div:first-child span {
    font-size: 16px; }
  .pickup-date-and-time .fields-inline .full-size {
    -webkit-box: 1;
    -moz-box: 1;
    -webkit-flex: 1 0 100px 0 auto;
    -ms-flexbox: 1 0 100px 0 auto;
    flex: 1 0 100px; }
  .pickup-date-and-time .fields-inline .full-size:first-child {
    -webkit-box: 1;
    -moz-box: 1;
    -webkit-flex: 1 0 100% 0 auto;
    -ms-flexbox: 1 0 100% 0 auto;
    flex: 1 0 100%; } }

